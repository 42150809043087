/*
*  HERO
*/

@import 'sass/settings/variables';
@import 'sass/settings/functions';
@import 'sass/settings/mixins';

.hero {
	padding-top: 0;
	padding-bottom: 0;
	position: relative;
	@include media-above('lg') {
		& > .wrapper {
			position: relative;
		}
		& + section.intro {
			padding-top: 0;
		}
	}
}

.hero__bg {
	position: absolute;
	top: 0;
	left: 15vw;
	right: 0;
	z-index: -1;
	width: 200vw;
	svg {
		display: inline-block;
		width: 100%;
	}
	@include media-above('sm') {
		width: 150vw;
		left: 9vw;
	}
	@include media-above('lg') {
		width: 100vw;
		left: $space-lg;
	}
}

.hero__eyebrow {
	width: 70vw;
	max-width: 360px;
	margin-top: 4.5vw;
	margin-left: -4.5vw;
  	margin-bottom: $space-md;
	@include media-above('sm') {
		margin-top: 3.5vw;
		margin-left: -3vw;
	}
	@include media-above('lg') {
		max-width: 460px;
		margin-top: 2.3vw;
		margin-left: -$space;
    	margin-bottom: $space-lg;
	}
	@include media-above('xxxl') {
		margin-left: -$space-lg;
	}
}

.hero__title {
	font-family: $base-font;
	font-weight: 700;
	font-size: $text-sm-bump;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: $brand-raspberry;
	margin-bottom: 0.8em;
}

.hero__img {
	width: 100%;
	@include media-above('md') {
		width: 88vw;
		margin-left: 12vw;
	}
	@include media-above('xxl') {
		width: 83vw;
		margin-left: 17vw;
	}
	@include media-above('xxxl') {
		width: calc(50vw + 470px);
		margin-left: calc(50vw - 470px);
		margin-right: 0;
	}
}
